.footer {
  background: 
    linear-gradient(135deg, rgba(8, 28, 36, 0.9) 2.88%, rgba(14, 50, 70, 0.9) 98.14%),
    url('../../assets/Decor.png') no-repeat center center fixed;
  background-size: cover;
  width: 100%;
  min-height: 15rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  box-sizing: border-box;
  overflow: hidden;
  padding: 2rem 0;
}

.footer .chakra-text {
  z-index: 2;
}

.footer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../assets/Decor.png');
  background-size: cover;
  opacity: 0.2;
  z-index: 1;
}

@media (max-width: 768px) {
  .footer {
    padding: 2rem 1rem;
  }

  .footer .chakra-text {
    font-size: 1rem;
  }

  .footer .chakra-flex {
    text-align: center;
  }
}