/* src/styles/Dashboard/JobTile.css */

.job-card {
    background-color: rgba(14, 50, 70, 0.6);
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
    transition: transform 0.3s ease;
    position: relative;
    color: #E1E1E1;
  }
  
  .job-card:hover {
    transform: translateY(-5px);
  }
  
  .job-title {
    font-size: 1rem;
    font-weight: bold;
    color: #E1E1E1;
    margin-top: 0.5rem;
  }
  
  .job-description {
    margin: 0.5rem 0;
    color: #B0BEC5;
  }
  
  .job-location {
    margin-bottom: 0.5rem;
    color: #B0BEC5;
  }
  
  .location-icon {
    color: #00B4DB;
    margin-right: 0.5rem;
  }
  
  .job-date {
    color: #B0BEC5;
    font-size: 0.8rem;
  }
  
  .job-details {
    color: #00B4DB !important;
    font-weight: 500;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .job-details:hover {
    color: #f5df88;
  }
  
  .status {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    font-weight: bold;
    padding: 0.1rem 0.4rem;
    border-radius: 12px;
    font-size: 0.7rem;
    color: white;
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  .status.active {
    background-color: rgba(40, 167, 69, 0.2);
    color: #28a745;
  }
  
  .status.not-active {
    background-color: rgba(231, 76, 60, 0.2);
    color: #e74c3c;
  }