/* QualitySlides Section CSS */
.quality-slides-section {
  background: linear-gradient(
      135deg,
      rgba(8, 28, 36, 0.9) 2.88%,
      rgba(14, 50, 70, 0.9) 98.14%
    ),
    url("../../assets/Decor.png") no-repeat center center fixed;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  padding: 1rem 15px; /* Reduced padding */
  box-sizing: border-box;
  position: relative;
  z-index: 1;
  margin: 0;
  overflow: hidden;
}

.quality-slides-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/Decor.png");
  background-size: cover;
  opacity: 0.2;
  z-index: 2;
}

.quality-slides-section .main-heading {
  color: white;
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 1rem;
  z-index: 3;
}

.quality-slides-section .description {
  font-size: 1.1rem;
  margin-bottom: 1.5rem;
  color: #e1e1e1;
  z-index: 3;
}

.quality-slides-section .cta-button {
  background-color: #00b4db;
  background-image: linear-gradient(to right, #00b4db, #0083b0);
  color: white;
  padding: 0.75rem 1.5rem; /* Adjusted button padding */
  font-size: 0.9rem; /* Reduced button font size */
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  z-index: 3;
}

.quality-slides-section .cta-button:hover {
  background-image: linear-gradient(to right, #0083b0, #00b4db);
}

@media (max-width: 768px) {
  .quality-slides-section {
    padding: 1rem 10px; /* Further reduced padding on mobile */
  }

  .quality-slides-section .main-heading {
    font-size: 2rem; /* Further reduced heading size on mobile */
  }

  .quality-slides-section .description {
    font-size: 0.9rem; /* Further reduced description size on mobile */
  }

  .quality-slides-section .cta-button {
    padding: 0.5rem 1rem; /* Further adjusted button padding on mobile */
  }
}