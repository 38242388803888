/* Navbar CSS */
.navbar {
  background: transparent; /* Make navbar background transparent */
  padding: 1rem 1rem;
  position: absolute; /* Allow content to flow underneath */
  width: 100%;
  z-index: 10;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 0;
}

.navbar-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.navbar-logo {
  height: 50px;
  width: auto;
}

.nav-links {
  display: flex;
  align-items: center;
  list-style: none;
}

.nav-links li {
  margin-left: 2rem;
}

.nav-links a {
  color: #fff; /* Maintain white text for contrast */
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s;
}

.nav-links a:hover {
  color: #f5df88;
}

.login-btn {
  border: 1px solid #C5935B;
  background-color: #C5935B;
  color: #fff;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 5px;
  transition: background-color 0.3s, border-color 0.3s;
}

.login-btn:hover {
  background-color: #A47C45;
  border-color: #A47C45;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .navbar{
    padding-bottom: 0;
  }
  .navbar-content {
    flex-direction: column;
  }

  .navbar-logo {
    margin-bottom: 1rem;
    height: 40px;
  }

  .nav-links {
    display: none;
    flex-direction: column;
    align-items: center;
    background-color: #223F4C;
    width: 100vw;
    padding: 1rem 0;
  }

  .nav-links.active {
    display: flex;
  }

  .nav-links li {
    margin: 0.5rem 0;
  }
}