/* Recruitment Section CSS */
.recruitment-section {
  background: 
    linear-gradient(135deg, rgba(8, 28, 36, 0.9) 2.88%, rgba(14, 50, 70, 0.9) 98.14%),
    url('../../assets/Decor.png') no-repeat center center fixed; /* Use 'fixed' for background continuity */
  background-size: cover;
  min-height: 100vh; /* Minimum height to cover the entire viewport */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  padding: 30vh 20px; /* Adjust padding to accommodate navbar */
  box-sizing: border-box;
  position: relative;
  z-index: 2;
  margin: 0;
  overflow: hidden;
}

.recruitment-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../assets/Decor.png');
  background-size: cover;
  opacity: 0.2;
  z-index: 2;
}

.recruitment-section .heading {
  color: #41B883;
  font-weight: bold;
  z-index: 3;
}

.recruitment-section .sub-heading {
  color: #E74C3C;
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 1rem;
  z-index: 3;
}

.recruitment-section .main-heading {
  color: white;
  font-size: 3.5rem;
  line-height: 1.2;
  margin-bottom: 1rem;
  z-index: 3;
}

.recruitment-section .description {
  font-size: 1.25rem;
  margin-bottom: 2rem;
  color: #E1E1E1;
  z-index: 3;
}

.recruitment-section .cta-button {
  background-color: #00B4DB;
  background-image: linear-gradient(to right, #00B4DB, #0083B0);
  color: white;
  padding: 1rem 2rem;
  font-size: 1rem;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  z-index: 3;
}

.recruitment-section .cta-button:hover {
  background-image: linear-gradient(to right, #0083B0, #00B4DB);
}

.google-signin-button {
  width: 200px; 
  margin: 20px auto;
}

@media (max-width: 768px) {
  .recruitment-section .main-heading {
    font-size: 2.5rem;
  }

  .recruitment-section .description {
    font-size: 1rem;
  }

  .recruitment-section .cta-button {
    padding: 0.75rem 1.5rem;
  }
}