.hire-candidate-page {
  background: url('../../assets/Decor.png') center center no-repeat,
              linear-gradient(135deg, rgba(8, 28, 36, 0.9) 2.88%, rgba(14, 50, 70, 0.9) 98.14%);
  background-size: cover;
  padding: 3rem;
  border-radius: 8px;
  color: white;
  max-width: 100%; /* Cover full width of the right panel */
  height: calc(100vh - 2rem); /* Adjust height to cover the viewport */
  overflow-y: auto;
}

.hire-title {
  text-align: center;
  margin-bottom: 2.5rem;
  font-weight: bold;
  font-size: 2.5rem !important; /* Increased font size for more prominence */
}

.progress-indicator {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3rem;
}

.step {
  text-align: center;
  position: relative;
  flex-grow: 1; /* Allow steps to grow and distribute evenly */
}

.step .circle {
  width: 30px;
  height: 30px;
  background-color: #00B4DB;
  border-radius: 50%;
  margin: 0 auto;
  position: relative;
  z-index: 1; /* Make sure circles are above the line */
}

.step .circle.current-step .inner-circle {
  width: 15px;
  height: 15px;
  background-color: black; /* Inner circle for current step */
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.step.completed .circle {
  background-color: #0083B0;
}

.step-label {
  margin-top: 0.5rem;
  font-size: 0.9rem;
}

.form-container {
  display: grid;
  gap: 1rem;
  padding-top: 4rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.input-field {
  width: 100%;
  padding: 0.75rem;
  border: none;
  border-bottom: 2px solid rgba(255, 255, 255, 0.5); /* Bottom border */
  background-color: transparent; /* Remove background color */
  color: white;
  transition: border-color 0.3s;
}

.input-field:focus {
  border-bottom: 2px solid #00B4DB; /* Focus color change */
}

.select-field {
  width: 100%;
  padding: -1rem; /* Corrected padding */
  border: none;
  border-bottom: 2px solid rgba(255, 255, 255, 0.5); /* Bottom border */
  background-color: transparent; /* Remove background color */
  color: white;
  transition: border-color 0.3s;
  -webkit-appearance: none; /* Remove default arrow in some browsers */
  appearance: none;
}

.select-field:focus {
  border-bottom: 2px solid #00B4DB; /* Focus color change */
}

.select-field option {
  color: black; /* Option text color */
  background-color: white; /* Option background */
}

.next-button {
  background-color: #00B4DB !important;
  background-image: linear-gradient(to right, #00B4DB, #0083B0) !important; /* Gradient background */
  color: white;
  font-weight: bold;
  border-radius: 8px;
  padding: 0.75rem 1.5rem;
  transition: background-color 0.3s;
  display: block;
  margin: auto;
}

.next-button:hover {
  background-color: #0083B0;
}

.jd-enhancements, .searching-match {
  text-align: center;
  padding: 2rem;
}

.jd-enhancements .form-group, .searching-match .form-group {
  margin-bottom: 1rem;
}
