/* CTABanner Section CSS */
.cta-banner {
    background: 
      linear-gradient(135deg, rgba(8, 28, 36, 0.9) 2.88%, rgba(14, 50, 70, 0.9) 98.14%),
      url('../../assets/Decor.png') no-repeat center center fixed;
    background-size: cover;
    width: 100%;
    min-height: 17rem; /* Match height for consistency */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4rem 2rem; /* Padding for spacing */
    color: white;
    text-align: center;
    position: relative;
    z-index: 0; /* Ensure it's not above other content */
    box-sizing: border-box;
    overflow: hidden;
  }
  
  .cta-banner::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../assets/Decor.png');
    background-size: cover;
    opacity: 0.2;
    z-index: -1; /* Ensure background layer is behind content */
  }
  
  @media (max-width: 768px) {
    .cta-banner {
      padding: 2rem 1rem;
    }
  
    .cta-banner h2 {
      font-size: 1.5rem;
    }
  
    .cta-banner p {
      font-size: 1rem;
    }
  }