/* src/styles/Dashboard/Sidebar.css */

.sidebar {
  background-color: rgba(0, 0, 0, 0.3); /* Darker background */
  padding: 1rem;
  border-radius: 8px;
  width: 20rem; /* Adjust width */
  height: 100vh;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: fixed; /* Fix position for overlay */
  z-index: 20;
  transition: transform 0.3s ease;
  transform: translateX(-100%); /* Hide sidebar initially on mobile */
}

.sidebar.open {
  transform: translateX(0); /* Slide in on toggle */
}

.logo {
  text-align: center;
  margin-bottom: 1rem;
  margin-top: 5rem; /* Add top margin */
  cursor: pointer;
}

.divider {
  border-top: 1px solid #fff; /* Add a divider line */
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.menu {
  list-style-type: none;
  padding: 0;
  flex-grow: 1;
}

.menu-item {
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s;
  display: flex; /* Display items in flex */
  align-items: center; /* Center items vertically */
  padding: 0.5rem 0;
  cursor: pointer;
}

.menu-icon {
  margin-right: 10px; /* Space between icon and text */
}

.menu-item:hover {
  color: #f5df88; /* Hover color */
}

.menu-item.active {
  color: #00B4DB; /* Active color */
}

.close-button {
  display: none;
  background: none;
  border: none;
  color: white;
  font-size: 2rem;
  position: absolute;
  bottom: -40rem;
  right: 1rem;
  cursor: pointer;
  transition: color 0.3s;
}

.close-button:hover {
  color: #f5df88;
}

@media (min-width: 768px) {
  .sidebar {
    transform: translateX(0); /* Show sidebar on larger screens */
    position: relative; /* Not fixed on larger screens */
  }
}

@media (max-width: 768px) {
  .sidebar {
    width: 50vw; /* Occupy 50% of the screen width on mobile */
    background-color: #0A1F27;
  }

  .close-button{
    display: block;
  }

  .sidebar.open {
    display: block; /* Ensure sidebar displays when open */
  }
}