/* FAQs Section CSS */
.faqs-section {
    background: 
      linear-gradient(135deg, rgba(8, 28, 36, 0.9) 2.88%, rgba(14, 50, 70, 0.9) 98.14%),
      url('../../assets/Decor.png') no-repeat center center fixed;
    background-size: cover;
    min-height: 98vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    padding: 20vh 0; /* Adjusted padding for better alignment */
    box-sizing: border-box;
    position: relative;
    z-index: 2;
    margin: 0;
    overflow: hidden;
  }
  
  .faqs-section::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../assets/Decor.png');
    background-size: cover;
    opacity: 0.2;
    z-index: 2;
  }
  
  .faqs-section .chakra-heading {
    color: #41B883;
    font-weight: bold;
    z-index: 3;
  }
  
  .faqs-section .chakra-card {
    background-color: rgba(255, 255, 255, 0.95);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 3;
  }
  
  .faqs-section .chakra-accordion__button {
    background-color: #f0f0f0;
    color: #333;
    transition: background-color 0.3s ease;
    border-bottom: 1px solid #ddd;
  }
  
  .faqs-section .chakra-accordion__button:hover {
    background-color: #e0e0e0;
  }
  
  .faqs-section .chakra-accordion__panel {
    color: #333;
  }
  
  .faqs-section .chakra-icon {
    color: #41B883;
  }
  
  @media (max-width: 768px) {
    .faqs-section .chakra-heading {
      font-size: 2rem;
    }
  
    .faqs-section .chakra-text {
      font-size: 1rem;
    }
  }