/* src/styles/Home/loginPopup.css */

.login-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black for the overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.login-popup {
  background: linear-gradient(
    135deg,
    #081c24, /* Solid color 1 */
    #0e3246  /* Solid color 2 */
  ); /* Background gradient without transparency */
  border-radius: 15px; /* Adjusted border radius for the popup */
  padding: 30px 40px; /* Added padding for the popup */
  width: 400px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 1001;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white; /* Set text color to white */
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  color: white; /* White color for close button */
  cursor: pointer;
}

.login-popup h2 {
  color: white; /* White color for heading */
  font-size: 1.5rem;
}

.login-popup p {
  margin-bottom: 20px;
  color: #e1e1e1; /* Light grey for paragraph text */
  text-align: center; /* Center align text for consistency */
}

.google-btn{
  gap: 1rem;
}




.login-button {
  width: 100%;
  padding: 12px; /* Padding inside the button */
  background-color: #00b4db;
  background-image: linear-gradient(to right, #00b4db, #0083b0);
  color: white; /* White color for button text */
  border: none;
  border-radius: 1rem; /* Adjusted border radius for the button */
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  margin-bottom: 20px; /* Space between button and terms */
  outline: none; /* Remove outline for better aesthetics */
}

.login-button:hover {
  background-image: linear-gradient(to right, #0083b0, #00b4db); /* Hover effect */
}

.terms {
  margin-top: 10px;
  font-size: 0.8rem;
  color: #e1e1e1; /* Light grey for terms text */
  text-align: center; /* Center align terms text */
}

.terms a {
  color: #41b883; /* Green color for links */
  text-decoration: underline;
}


