/* src/styles/Dashboard/Dashboard.css */

.dashboard-container {
  background: url('../../assets/Decor.png') center center no-repeat, linear-gradient(135deg, rgba(8, 28, 36, 0.9) 2.88%, rgba(14, 50, 70, 0.9) 98.14%);
  background-size: cover;
  height: 100vh;
  color: white;
  display: flex;
  flex-direction: column;
}

.main-grid {
  height: 100%;
}

.header-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.hire-button-desktop {
  background-color: #00B4DB !important;
  background-image: linear-gradient(to right, #00B4DB, #0083B0) !important; /* Gradient background */
  color: white;
  font-weight: bold;
  border-radius: 8px;
  padding: 0.5rem 1rem;
  transition: background-color 0.3s;
}

.hire-button {
  background-color: #00B4DB !important;
  background-image: linear-gradient(to right, #00B4DB, #0083B0) !important; /* Gradient background */
  display: none !important;
}

.hire-button-desktop:hover {
  background-color: #0083B0;
}

.logo-img {
  display: none; /* Initially hide the logo */
}

.content {
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  flex-grow: 1;
  overflow-y: auto;
  height: 100%;
}

.search-bar {
  margin-bottom: 1rem;
}

.search-input {
  width: 100%;
  padding: 0.5rem;
  border-radius: 5px;
  border: none;
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
}

.job-cards {
  display: grid;
  gap: 1rem;
}

@media (min-width: 768px) {

  .content {
    margin-left: 1rem;
  }

  .job-cards {
    grid-template-columns: repeat(3, 1fr);
  }

  .logo-img {
    display: none; /* Keep logo hidden on larger screens */
  }

  .hire-button {
    display: none; /* Ensure the mobile button stays hidden */
  }

  .hire-button-desktop {
    display: block; /* Ensure desktop button is visible */
  }
}

@media (max-width: 767px) {
  .dashboard-container {
    padding: 0rem;
  }

  .logo-img {
    display: block; /* Show logo on mobile */
    position: absolute;
    left: 0;
    top: 0;
    margin-left: 1rem;
    margin-top: 0.5rem;
    width: 30px; /* Adjust size as needed */
    height: 30px;
  }

  .page-title {
    text-align: center; /* Center the title on mobile */
    flex-grow: unset;
  }

  .hire-button {
    display: block !important; /* Show the mobile button */
    background-color: #00B4DB !important; /* Inherit the desktop button's styles */
    color: white !important;
    font-weight: bold !important;
    border-radius: 50% !important; /* Make it circular */
    width: 40px !important;
    height: 40px !important;
    font-size: 1.5rem !important;
    transition: background-color 0.3s;
    position: absolute !important;
    right: 1rem !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .hire-button:hover {
    background-color: #0083B0; /* Hover effect */
  }

  .hire-button-desktop {
    display: none !important; /* Hide the desktop button on mobile */
  }

  .job-cards {
    grid-template-columns: 1fr; /* Single column on mobile */
  }

  .header-bar {
    justify-content: center; /* Center the elements on mobile */
    position: relative;
  }
}