.editor-container {
  display: grid;
  grid-template-areas:
    "peer-id peer-id"
    "editor video-container"
    "output output";
  grid-template-columns: 70% 30%; /* Adjusted to give editor more space */
  grid-template-rows: auto 1fr auto;
  gap: 20px;
  padding: 8rem 5rem;
  background: url('../../assets/Decor.png') center center no-repeat, linear-gradient(135deg, rgba(8, 28, 36, 0.9) 2.88%, rgba(14, 50, 70, 0.9) 98.14%);
  background-size: cover;
  border-radius: 8px;
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.interview-options {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;
  border-radius: 8px;
  margin-left: 25vw;
}

.interview-options button {
  padding: 1rem 2rem;
  font-size: 18px;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.interview-options button:hover {
  background-color: #0056b3;
}

.peer-id {
  grid-area: peer-id;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 2rem;
}

.peer-id-display {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.peer-id-display span {
  margin-right: 10px;
  font-size: 16px;
  color: white;
}

.peer-id button {
  padding: 8px 16px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 10px;
  transition: background-color 0.3s;
}

.peer-id button:hover {
  background-color: #0056b3;
}

.join-interview {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 2rem;
  border-radius: 8px;
}

.join-interview input {
  padding: 0.5rem;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
  color: #000; /* Text color for visibility */
  background-color: #f8f9fa; /* Light background for better visibility */
}

.join-interview button {
  padding: 0.5rem 1rem;
  font-size: 16px;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.join-interview button:hover {
  background-color: #0056b3;
}

.editor-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  grid-area: editor-header;
}

.editor-header h3 {
  margin: 0;
  font-size: 24px;
  color: white;
  font-weight: bold;
}

.language-selector select {
  padding: 0.5rem;
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 5px;
}

.language-selector select:focus {
  outline: none;
  border-color: #00B4DB;
}

.editor-output-container {
  grid-area: editor;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.editor-video-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  width: 95vw;
}

.editor-section {
  flex: 1;
  height: 60vh;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 8px;
  padding: 1rem;
}

.video-container {
  width: 30%; /* Adjust this to make sure videos are well aligned */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20px; /* Adjusted for better spacing between videos */
  height: 60vh;
}

.video-wrapper {
  position: relative;
}


.display-name-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  font-size: 16px;
  color: blue; /* Blue text inside the input field */
  border: 2px solid #007bff; /* Blue border */
  border-radius: 5px; /* Rounded corners */
  box-shadow: 0px 0px 5px rgba(0, 123, 255, 0.5); /* Light blue shadow for better appearance */
  outline: none; /* Remove default outline */
  transition: border-color 0.3s, box-shadow 0.3s; /* Smooth transition for focus effect */
}

.display-name-input::placeholder {
  color: #007bff; /* Blue placeholder text */
  opacity: 0.7; /* Slightly faded placeholder */
}

.display-name-input:focus {
  border-color: #0056b3; /* Darker blue on focus */
  box-shadow: 0px 0px 8px rgba(0, 86, 179, 0.7); /* Darker blue shadow on focus */
}

video {
  width: 100%;
  max-width: 300px;
  height: 28vh;
  border: 2px solid #ddd;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.display-name-label {
  position: absolute;
  bottom: 10px; /* Adjusts the vertical position */
  left: 10px;  /* Adjusts the horizontal position */
  font-weight: bold;
  font-size: 14px;
  color: white;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background for better readability */
  padding: 2px 5px;
  border-radius: 3px;
}



.run-button {
  padding: 0.5rem 1rem;
  background-color: #00B4DB;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-bottom: 1rem;
  align-self: flex-start;
  margin-top: 1rem;
}

.run-button:hover {
  background-color: #0083B0;
}

.output-section {
  grid-area: output;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 8px;
  padding: 1rem;
  height: 40vh;
  overflow-y: auto;
}

.output-panel {
  width: 100%;
  flex-grow: 1;
}

.save-icon-container {
  position: relative;
  top: -2px;
  right: -1px;

}

.save-icon {
  font-size: 24px;
  cursor: pointer;
}

.save-icon:hover {
  color: #007bff;
}

.save-recording-button {
  background-color: #4CAF50; /* Green background */
  border: none; /* Remove borders */
  color: white; /* White text */
  padding: 12px 24px; /* Some padding */
  text-align: center; /* Centered text */
  text-decoration: none; /* Remove underline */
  display: inline-block; /* Make the button inline-block */
  font-size: 16px; /* Increase font size */
  margin: 16px 0; /* Some margin on top and bottom */
  cursor: pointer; /* Pointer/hand icon */
  border-radius: 4px; /* Rounded corners */
  transition: background-color 0.3s ease; /* Smooth transition for background color */
}

.save-recording-button:hover {
  background-color: #45a049; /* Darker green on hover */
}

.save-recording-button:active {
  background-color: #3e8e41; /* Even darker green when the button is pressed */
  transform: scale(0.98); /* Slightly shrink the button */
}

.save-recording-button:focus {
  outline: none; /* Remove outline on focus */
  box-shadow: 0 0 0 2px #45a049; /* Add a greenish outline */
}

@media (max-width: 768px) {
  .editor-container {
    grid-template-areas:
      "peer-id"
      "editor-header"
      "editor"
      "video-container"
      "output";
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto auto;
    padding: 2rem;
  }

  .editor-header {
    justify-content: center;
    margin-bottom: 1rem;
  }

  .editor-video-container {
    flex-direction: column;
  }

  .editor-section {
    height: 40vh;
  }

  .video-container {
    height: auto;
    flex-direction: column;
    margin-top: 10px;
    width: 100%;
  }

  video {
    width: 80%;
    max-width: 100%;
    margin-bottom: 10px;
  }

  .peer-id {
    flex-direction: column;
  }

  .peer-id input {
    margin-bottom: 10px;
    width: 100%;
  }
}